body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
  font-display: swap;
}
.ui.sticky {
  z-index: 30;
}
/* Center the tabs: https://github.com/Semantic-Org/Semantic-UI/issues/5589 */
.ui.secondary.pointing.menu a:first-child {
  margin-left: auto;
}

.ui.secondary.pointing.menu a:last-child {
  margin-right: auto;
}

.ui.menu:not(.vertical) .left.menu {
  margin-right: auto !important;
}

.edititem:hover {
  cursor: pointer;
}

.ui.compact.grid > .row {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.ui.medspace.grid > .row {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.bdpgmenu > .sticky {
  z-index: 50;
}

.editgoalaccount {
  max-width: 400px;
}

.ui.dimmer {
  position: fixed;
}